

import Mainbox from "./components/mainbox/Mainbox"
import "./itemtosale.scss"

const ItemToSale = () => {
   return (
      <div className='itemtosale'>
         <Mainbox />
      </div>
   )
}

export default ItemToSale