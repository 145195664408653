

import "./header.scss"
import Headline from "./components/headline/Headline"
import Navigation from "./components/navigation/Navigation"

const Header = () => {
   return (
      <div className='header'>
         <div className="header__box">
            <Headline />
            <Navigation />
         </div>
         <div className="header__holder"></div>
      </div>
   )
}

export default Header