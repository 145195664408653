

import { FaPhoneAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import mainlogo from "../images/logo.png"
import { Link } from "react-router-dom"
import "./headline.scss"

const Headline = () => {
   return (
      <div className='headline'>
         <div className="headline__logo">
            <Link to="/" className="headline__logo-link"><img src={mainlogo}/></Link>
         </div>

         <div className="headline__content">
            <div className="headline__address addres">
               <div className="addres__ico"><ImLocation /></div>
               <div className="addres__content">
                  <span>Волинська обл. м. Ківерці, вулиця Шевченка 20 б</span>
                  <a target="_blank" href="#">На мапі</a>
                  <p>Відвідування відбуваються за попереднім записом</p>
               </div>
            </div>
            <div className="headline__phone">
               <a href="tel:+48739137066"><FaPhoneAlt/><span>+48 739 137 066</span></a>
            </div>
            <div className="headline__btn btn">
               <Link to="/" className="btn__btn">Записатись на візит</Link>
            </div>
         </div>

         <div className="headline__languages">
            <a href="#">ENG</a>
            <a href="#" className="active">УКР</a>
         </div>
      </div>
   )
}

export default Headline