

import { Link } from "react-router-dom"
import "./goods.scss"

const Goods = ({img, name, subtitle, path}) => {
   return (
      <a href={path} className='goods'>
         <div className="goods__img">
            <img src={img}/>
         </div>
         <div className="goods__text">
            <h3>{name}</h3>
            <p>{subtitle}</p>
         </div>
      </a>
   )
}

export default Goods