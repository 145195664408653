
import logo1 from "../images/demo.jpg"
import Goods from "../goods/Goods"
import "./navigation.scss"

const pinobetonArr = [
   {
      name: "ПБС-30 ПВМ",
      subtitle: "Пінобетонне обладнання мобільного типу!",
      img: logo1,
      path: "/itemtosale/1",
   },
   {
      name: "pinobetonna bochka hahah",
      subtitle: "",
      img: logo1,
      path: "/",
   },
   {
      name: "pinobetonna bochka hahah",
      subtitle: "",
      img: logo1,
      path: "/",
   },
   {
      name: "pinobetonna bochka hahah",
      subtitle: "",
      img: logo1,
      path: "/",
   },
]

const Navigation = () => {
   return (
      <>
         <nav className='navigation'>
            <div className="navigation__wrapper">
               <div className="navigation__button drop">
                  Пінобетон
                  <div className="drop__window">
                     <div className="drop__window-wrap">
                        <div className="drop__window-sidebar">
                           <div className="drop__window-sidebar-item">Модельний ряд</div>
                           <div className="drop__window-sidebar-item">Гібриди тойота</div>
                           <div className="drop__window-sidebar-item">Авто у наявності</div>
                           <div className="drop__window-sidebar-item">Авто з пробігом</div>
                           <div className="drop__window-sidebar-item">Авто на тест драйві</div>
                        </div>
                        <div className="drop__window-content">
                           {
                              pinobetonArr.map(i => <Goods name={i.name} img={i.img} subtitle={i.subtitle} path={i.path}/>)
                           }
                        </div>
                     </div>
                  </div>
               </div>
               <div className="navigation__button drop">Газобетон</div>
               <div className="navigation__button drop">Сервіс і підтримка</div>
               <div className="navigation__button drop">Комплектуючі</div>
               <div className="navigation__button">Акції та новини</div>
               <div className="navigation__button drop">Betonotehnika</div>
            </div>
         </nav>
      </>
   )
}

export default Navigation