

import "./mainbox.scss"

const info = [
   {
      title: "Yaris Гібрид четвертого покоління",
      img: "https://cityplaza.toyota.ua/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSm5kbUZ5YVdGdWRITXZOVmxRVEdWNVEySm1aRlJ1V0hsdGNIbFFlVXhHUzBSbUx6RmxaREprTnpFMU9EWTRaamt5TmpOa1lUVTFNREZqTWpBeE16a3hNelF3WXpReE9UZzFNVEEwT0RZMVptVmpaVGt6Tnpsak0yTmtNakprT0dRM1lXRUdPZ1pGVkRvUVpHbHpjRzl6YVhScGIyNUpJa1ZwYm14cGJtVTdJR1pwYkdWdVlXMWxQU0paWVhKcGN5QXhMbmRsWW5BaU95Qm1hV3hsYm1GdFpTbzlWVlJHTFRnbkoxbGhjbWx6SlRJd01TNTNaV0p3QmpzR1ZEb1JZMjl1ZEdWdWRGOTBlWEJsU1NJUGFXMWhaMlV2ZDJWaWNBWTdCbFE2RVhObGNuWnBZMlZmYm1GdFpUb0tiRzlqWVd3PSIsImV4cCI6IjIxMjQtMDUtMDJUMTI6NDk6NDYuNDg4WiIsInB1ciI6ImJsb2Jfa2V5In19--cbb7ff1795d4cae24d375b9e684c8461c45429f5/Yaris%201.webp",
      text: "Автомобіль, що відповідає вимогам міського стилю життя, і водночас керувати ним на заміських дорогах та шосе ― саме задоволення.",
   },
   {
      title: "Дизайн інтер’єру",
      img: "https://cityplaza.toyota.ua/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSm5kbUZ5YVdGdWRITXZOMUpHV1ZFNFFqUTVRbWMzZWpsd2VsaHZOMkp2TlUxU0x6RmxaREprTnpFMU9EWTRaamt5TmpOa1lUVTFNREZqTWpBeE16a3hNelF3WXpReE9UZzFNVEEwT0RZMVptVmpaVGt6Tnpsak0yTmtNakprT0dRM1lXRUdPZ1pGVkRvUVpHbHpjRzl6YVhScGIyNUpJa1ZwYm14cGJtVTdJR1pwYkdWdVlXMWxQU0paWVhKcGN5QXlMbmRsWW5BaU95Qm1hV3hsYm1GdFpTbzlWVlJHTFRnbkoxbGhjbWx6SlRJd01pNTNaV0p3QmpzR1ZEb1JZMjl1ZEdWdWRGOTBlWEJsU1NJUGFXMWhaMlV2ZDJWaWNBWTdCbFE2RVhObGNuWnBZMlZmYm1GdFpUb0tiRzlqWVd3PSIsImV4cCI6IjIxMjQtMDUtMDJUMTI6NDk6NDYuNDkwWiIsInB1ciI6ImJsb2Jfa2V5In19--f191100144ce4f3832b8f53f024a69be1f6383f9/Yaris%202.webp",
      text: "Інтер’єр нового Yaris із загальною концепцією «Менше ― це більше». Це чітко та якісно спроєктований простір, приємний для ока й на дотик, а також просторий, наче в автомобіля більшого розміру.",
   },
   {
      title: "Динамічні показники та викиди СО2",
      img: "https://cityplaza.toyota.ua/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSm5kbUZ5YVdGdWRITXZNbUUzVWtwSWFsbGxVMk5EU0ZNM2RsSkxiMkpXVVRGNkx6RmxaREprTnpFMU9EWTRaamt5TmpOa1lUVTFNREZqTWpBeE16a3hNelF3WXpReE9UZzFNVEEwT0RZMVptVmpaVGt6Tnpsak0yTmtNakprT0dRM1lXRUdPZ1pGVkRvUVpHbHpjRzl6YVhScGIyNUpJa1ZwYm14cGJtVTdJR1pwYkdWdVlXMWxQU0paWVhKcGN5QXpMbmRsWW5BaU95Qm1hV3hsYm1GdFpTbzlWVlJHTFRnbkoxbGhjbWx6SlRJd015NTNaV0p3QmpzR1ZEb1JZMjl1ZEdWdWRGOTBlWEJsU1NJUGFXMWhaMlV2ZDJWaWNBWTdCbFE2RVhObGNuWnBZMlZmYm1GdFpUb0tiRzlqWVd3PSIsImV4cCI6IjIxMjQtMDUtMDJUMTI6NDk6NDYuNDkzWiIsInB1ciI6ImJsb2Jfa2V5In19--a81fddea7201706b86470305b516f1879b388f9e/Yaris%203.webp",
      text: "Завдяки новій гібридній системі четвертого покоління автомобіль має потужність у 116 кінських сил, низький рівень викидів CO2 ― 85 г/км ― та економно використовує 3,1 л пального на 100 км пробігу (у комбінованому циклі).",
   },
]

const BlockContent = ({ img, title, text }) => {
   return (
      <div className="blockcontent">
         <img src={img} />
         <h3>{title}</h3>
         <p>{text}</p>
      </div>
   )
}

const Mainbox = () => {
   return (
      <div className='mainbox'>
         <div className="mainbox__wrapper">
            <h1 className="mainbox__title">Огляд</h1>
            <div className="mainbox__divide">
               <div className="mainbox__first">
                  <BlockContent img={info[0].img} title={info[0].title} text={info[0].text} />
               </div>
               <div className="mainbox__seck">
                  <BlockContent img={info[1].img} title={info[1].title} text={info[1].text} />
                  <BlockContent img={info[2].img} title={info[2].title} text={info[2].text} />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Mainbox